// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contact-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-getinvolved-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/getinvolved.js" /* webpackChunkName: "component---src-pages-getinvolved-js" */),
  "component---src-pages-index-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-programs-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-rentspace-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/rentspace.js" /* webpackChunkName: "component---src-pages-rentspace-js" */),
  "component---src-pages-sample-page-2345678-js": () => import("/Users/keyariaw/Desktop/cflcypsi/src/pages/sample-page2345678.js" /* webpackChunkName: "component---src-pages-sample-page-2345678-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/keyariaw/Desktop/cflcypsi/.cache/data.json")

